@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(10deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes hover_animation {
    0% {
        transform: translateY(-2px);
    }
    25% {
        transform: translateY(-4px);
    }
    50% {
        transform: translateY(-4px);
    }
    75% {
        transform: translateY(-2px);
    }
    100% {
        transform: translateY(-0px);
    }
}

.drink-container {
    position: fixed;
    right: 20px;
    bottom: 50px;
    width: 100px;
    height: auto;
    z-index: 9999;
    display: block;
    border-radius: 50px;
    transition: all 0.2s ease;
}

.drink-container.expanded {
    background: whitesmoke;
    border: 1px #d7d7d7 solid;
}

.drink-categories {
    width: 100%;
    padding: 0px 10px;
    transform: translateY(100%);
    transition: transform 0.1s ease-out;
    overflow: hidden;
    max-height: 0;
}

.drink-categories-show {
    transform: translateY(0);
    max-height: 100%; /* Adjust height as needed */
    transition: transform 0.1s ease-out;
}

.drink,
.drink-main {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: 10px;
    cursor: pointer;
    display: block;
    text-align: center;
    font-family: cursive;
    font-weight: bolder;
    font-size: 11px;
    color: #494949;
}

.drink:hover {
    animation: hover_animation 0.8s ease-in-out 4;
}

.drink-main img,
.drink img {
    /* -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5)); */
}

.drink-main img:hover {
    -webkit-filter: drop-shadow(0px 0px 1px rgb(255, 255, 255));
    filter: drop-shadow(0px 0px 1px rgb(255, 255, 255));
    animation: rotate 0.3s ease 4;
}

.drink img {
    width: 60px;
    height: auto;
}

.drink-main {
    width: 90px;
    height: auto;
    display: flex;
    margin-left: 4px;
}
